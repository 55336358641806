<template>
  <bank-of-flyer-general
    :status="1"
  />
</template>

<script>

import bankOfFlyerGeneral
from '@/views/commons/components/bank-of-flyers/bank-of-flyers-module/bankOfFlyerGeneral/BankOfFlyerGeneral.vue'

export default {
  components: {
    bankOfFlyerGeneral,

  },
}
</script>

<style scoped>

</style>
